<template>
  <div
    v-loading="loading"
    class="forgot-password-page"
  >
    <el-form
      ref="forgotForm"
      class="auth-form"
      :model="forgotForm"
      :rules="rules"
    >
      <h2 class="form-header">
        Find your account
      </h2>
      <el-form-item
        label="Email address"
        prop="email"
      >
        <el-input
          v-model="forgotForm.email"
          type="email"
          placeholder="Enter email address"
          :disabled="loading"
        />
      </el-form-item>
      <button
        id="submit"
        type="submit"
        class="fgh-button lg fgh-button--primary btn-login"
        :disabled="loading"
        @click.prevent="onSubmit"
      >
        Search
      </button>
    </el-form>
  </div>
</template>

<script>
import authApi from '@/modules/auth/services/auth.api'
import authRules from '@/modules/auth/services/auth.rules'

export default {
  data() {
    return {
      forgotForm: {
        email :''
      },
      rules: {
        email: [
          { validator: authRules.validateEmailAddress }
        ]
      },
      loading: false
    }
  },

  methods: {
    onSubmit() {
      this.$refs.forgotForm.validate((valid) => {
        if (valid) {
          this.loading = true
          authApi.forgot(this.forgotForm.email)
            .then((response) => {
              this.loading = false
              this.$notify.success({
                title: 'Success',
                message: response.data.message
              })
              this.$router.push({ name: 'auth-login' })
            }).catch((error) => {
              this.loading = false
              this.$notify.error({
                title: 'Error',
                message: error.response.status == 422 ? error.response.data.errors.email[0] : 'An unexpected error occured. Please try again later'
              })
            })
        }
      })
    }
  },
}
</script>

<style lang="scss">
.forgot-password-page {
  .auth-form {
    max-width: 400px;
  }
}
</style>
